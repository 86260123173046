<template>
  <default-layout>
    <div class="confirm-info default-layout-main">
      <div class="my-4">
        <div class="text-left">
          <label class="input-label font-bold required">Số CMND/CCCD</label>
          <base-input v-model:modelValue="nicNumber" placeholder="CMND/CCCD" />
          <base-text-error :errorMessages="errorNicNumber" />
        </div>
      </div>
      <div class="my-4">
        <div class="text-left">
          <label class="input-label required font-bold">Ngày cấp</label>
          <base-date-picker
            v-model:modelValue="nicDateProvide"
            placeholder="Ngày cấp"
          />
          <base-text-error :errorMessages="errorNicDateProvide" />
        </div>
      </div>
      <div class="my-4">
        <div class="text-left">
          <label class="input-label required font-bold">Nơi cấp</label>
          <base-input v-model:modelValue="placeProvide" placeholder="Nơi cấp" />
          <base-text-error :errorMessages="errorPlaceProvide" />
        </div>
      </div>
      <div class="my-4">
        <div class="text-left">
          <label class="input-label font-bold required">Ngày sinh</label>
          <base-date-picker
            v-model:modelValue="birthday"
            placeholder="Ngày sinh"
          />
          <base-text-error :errorMessages="errorBirthday" />
        </div>
      </div>
      <div class="my-4">
        <div class="text-left">
          <label class="input-label font-bold required">Họ và tên</label>
          <base-input v-model:modelValue="fullName" placeholder="Họ và tên" />
          <base-text-error :errorMessages="errorName" />
        </div>
      </div>
      <div class="my-4">
        <div class="text-left">
          <p class="input-label font-bold required">Giới tính</p>
          <div class="flex items-center">
            <div class="mr-10">
              <input
                type="radio"
                id="male"
                class="mr-2"
                name="gender"
                value="true"
                v-model="gender"
                :checked="gender === 'nam'"
              />
              <label for="male">Nam</label><br />
            </div>
            <div>
              <input
                type="radio"
                id="female"
                class="mr-2"
                name="gender"
                value="false"
                v-model="gender"
                :checked="gender === 'nữ'"
              />
              <label for="female">Nữ</label><br />
            </div>
          </div>
        </div>
        <base-text-error :errorMessages="errorGender" />
      </div>
      <div class="my-4">
        <div class="text-left">
          <label class="input-label font-bold required"
            >Địa chỉ thường trú</label
          >
          <base-input v-model:modelValue="address" placeholder="Nhập địa chỉ" />
          <base-text-error :errorMessages="errorAdress" />
        </div>
      </div>
      <div class="my-4">
        <div class="text-left">
          <label class="input-label font-bold required">Loại giấy tờ</label>
          <base-select :data="dataCardType" v-model:modelValue="cardType" />
          <!-- <base-input
            :uppercase="!!cardType"
            v-model:modelValue="cardType"
            placeholder="Nhập loại giấy tờ"
          /> -->
          <base-text-error :errorMessages="errorCardType" />
        </div>
      </div>
      <base-button
        @click="handleSubmit"
        class="w-full border-16 bg-tertiary text-white my-10 py-6"
        >Tiếp tục
      </base-button>
    </div>
  </default-layout>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseDatePicker from '@/components/Base/BaseDatePicker.vue';
import ConstRouter from '@/constants/constRouter';
import { required, required2 } from '@/helpers/validate';
import { mapGetters, mapMutations } from 'vuex';
import { confirmData } from '@/apis/ApiEkyc';
// import { getApproval } from '@/helpers/localStorage';
import BaseSelect from '@/components/Base/baseSelect.vue';

export default defineComponent({
  name: 'EkycConfirm',
  components: {
    BaseButton,
    BaseTextError,
    BaseInput,
    DefaultLayout,
    BaseDatePicker,
    BaseSelect,
  },
  data() {
    return {
      nicNumber: '',
      birthday: '',
      fullName: '',
      address: '',
      nicDateProvide: '',
      placeProvide: '',
      gender: '',
      cardType: '',
      errorNicDateProvide: '',
      errorPlaceProvide: '',
      errorNicNumber: '',
      errorBirthday: '',
      errorName: '',
      errorAdress: '',
      errorGender: '',
      errorCardType: '',
      dataCardType: [
        {
          name: 'CMND',
          val: 'cmnd',
        },
        {
          name: 'CCCD',
          val: 'cccd',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getEkyc: 'getEkyc',
    }),
  },
  created() {
    if (this.getEkyc) {
      this.nicNumber = this.getEkyc.nicNumber;
      this.birthday = this.getEkyc.birthday;
      this.fullName = this.getEkyc.fullName;
      this.address = this.getEkyc.address;
      this.nicDateProvide = this.getEkyc.nicDateProvide;
      this.gender = this.getEkyc.gender === 'N/A' ? '' : this.getEkyc.gender;
      this.cardType = this.getEkyc.cardType;
      this.placeProvide =
        this.address &&
        this.address.split(',')[this.address.split(',')?.length - 1];
    }
  },
  methods: {
    ...mapMutations({
      setEkyc: 'setEkyc',
    }),
    validation() {
      if (
        !this.nicNumber ||
        !this.birthday ||
        !this.fullName ||
        !this.address ||
        !this.gender ||
        !this.cardType ||
        !this.nicDateProvide ||
        !this.placeProvide
      ) {
        this.errorNicNumber = required('NicNumber', this.nicNumber);
        this.errorBirthday = required('Birthday', this.birthday);
        this.errorName = required('Name', this.fullName);
        this.errorAdress = required('Address', this.address);
        this.errorGender = required2('Gender', this.gender);
        this.errorCardType = required('CardType', this.cardType);
        this.errorNicDateProvide = required(
          'nicDateProvide',
          this.nicDateProvide,
        );
        this.errorPlaceProvide = required('placeProvide', this.placeProvide);
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validation()) {
        this.setEkyc({
          fullName: this.fullName,
          nicNumber: this.nicNumber,
          birthday: this.birthday,
          address: this.address,
          gender: this.gender,
          nicDateProvide: this.nicDateProvide,
          cardType: this.cardType,
        });
        confirmData({
          fullName: this.fullName,
          nicNumber: this.nicNumber,
          birthday: this.birthday,
          address: this.address,
          gender: this.gender,
          nicDateProvide: this.nicDateProvide,
          cardType: this.cardType,
        })
          .then(() => {
            this.$router.push({
              name: ConstRouter.REGISTER_SUCCESS.name,
            });
            // if (res.data?.data?.isSupported) {
            //   this.$router.push({
            //     name: ConstRouter.REGISTER_SUCCESS.name,
            //   });
            // } else {
            //   this.$router.push({
            //     name: ConstRouter.BORROWER_CHOOSE_LOAN.name,
            //   });
            // }
          })
          .catch(err => {
            console.log(err);
          });

        // if (getApproval() == 'true') {
        //   this.$router.push({
        //     name: ConstRouter.REGISTER_SUCCESS.name,
        //   });
        // } else {
        //   // if not approve push to partner
        //   this.$router.push({
        //     name: ConstRouter.BORROWER_CHOOSE_LOAN.name,
        //   });
        // }
      }
    },
  },
});
</script>
<style lang="scss" scoped>
.required::after {
  margin-left: 2px;
  content: '*';
  color: red;
}
</style>
