
import { defineComponent } from 'vue';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseDatePicker from '@/components/Base/BaseDatePicker.vue';
import ConstRouter from '@/constants/constRouter';
import { required, required2 } from '@/helpers/validate';
import { mapGetters, mapMutations } from 'vuex';
import { confirmData } from '@/apis/ApiEkyc';
// import { getApproval } from '@/helpers/localStorage';
import BaseSelect from '@/components/Base/baseSelect.vue';

export default defineComponent({
  name: 'EkycConfirm',
  components: {
    BaseButton,
    BaseTextError,
    BaseInput,
    DefaultLayout,
    BaseDatePicker,
    BaseSelect,
  },
  data() {
    return {
      nicNumber: '',
      birthday: '',
      fullName: '',
      address: '',
      nicDateProvide: '',
      placeProvide: '',
      gender: '',
      cardType: '',
      errorNicDateProvide: '',
      errorPlaceProvide: '',
      errorNicNumber: '',
      errorBirthday: '',
      errorName: '',
      errorAdress: '',
      errorGender: '',
      errorCardType: '',
      dataCardType: [
        {
          name: 'CMND',
          val: 'cmnd',
        },
        {
          name: 'CCCD',
          val: 'cccd',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getEkyc: 'getEkyc',
    }),
  },
  created() {
    if (this.getEkyc) {
      this.nicNumber = this.getEkyc.nicNumber;
      this.birthday = this.getEkyc.birthday;
      this.fullName = this.getEkyc.fullName;
      this.address = this.getEkyc.address;
      this.nicDateProvide = this.getEkyc.nicDateProvide;
      this.gender = this.getEkyc.gender === 'N/A' ? '' : this.getEkyc.gender;
      this.cardType = this.getEkyc.cardType;
      this.placeProvide =
        this.address &&
        this.address.split(',')[this.address.split(',')?.length - 1];
    }
  },
  methods: {
    ...mapMutations({
      setEkyc: 'setEkyc',
    }),
    validation() {
      if (
        !this.nicNumber ||
        !this.birthday ||
        !this.fullName ||
        !this.address ||
        !this.gender ||
        !this.cardType ||
        !this.nicDateProvide ||
        !this.placeProvide
      ) {
        this.errorNicNumber = required('NicNumber', this.nicNumber);
        this.errorBirthday = required('Birthday', this.birthday);
        this.errorName = required('Name', this.fullName);
        this.errorAdress = required('Address', this.address);
        this.errorGender = required2('Gender', this.gender);
        this.errorCardType = required('CardType', this.cardType);
        this.errorNicDateProvide = required(
          'nicDateProvide',
          this.nicDateProvide,
        );
        this.errorPlaceProvide = required('placeProvide', this.placeProvide);
        return false;
      }
      return true;
    },
    handleSubmit() {
      if (this.validation()) {
        this.setEkyc({
          fullName: this.fullName,
          nicNumber: this.nicNumber,
          birthday: this.birthday,
          address: this.address,
          gender: this.gender,
          nicDateProvide: this.nicDateProvide,
          cardType: this.cardType,
        });
        confirmData({
          fullName: this.fullName,
          nicNumber: this.nicNumber,
          birthday: this.birthday,
          address: this.address,
          gender: this.gender,
          nicDateProvide: this.nicDateProvide,
          cardType: this.cardType,
        })
          .then(() => {
            this.$router.push({
              name: ConstRouter.REGISTER_SUCCESS.name,
            });
            // if (res.data?.data?.isSupported) {
            //   this.$router.push({
            //     name: ConstRouter.REGISTER_SUCCESS.name,
            //   });
            // } else {
            //   this.$router.push({
            //     name: ConstRouter.BORROWER_CHOOSE_LOAN.name,
            //   });
            // }
          })
          .catch(err => {
            console.log(err);
          });

        // if (getApproval() == 'true') {
        //   this.$router.push({
        //     name: ConstRouter.REGISTER_SUCCESS.name,
        //   });
        // } else {
        //   // if not approve push to partner
        //   this.$router.push({
        //     name: ConstRouter.BORROWER_CHOOSE_LOAN.name,
        //   });
        // }
      }
    },
  },
});
